import { onMounted, ref } from 'vue';
import { Language } from '@/interfaces';
import { getLanguages } from '@/services/api';
import { DEFAULT_LANGS_NAME, DEFAULT_LANG_CODE } from './constants';

type LangMap = Record<Language['code'], Language['name']>

export function useLang() {
  const langs = ref<Language[]>([]);
  const langMap = ref<LangMap>({ [DEFAULT_LANG_CODE]: DEFAULT_LANGS_NAME });

  const fetchLangMap = async() => {
    const { data } = await getLanguages();

    langs.value = data;
    langMap.value = data.reduce((pre, lang) => ({
      ...pre,
      [lang.code]: lang.name
    }), {});
  };

  onMounted(() => {
    fetchLangMap();
  });

  return { langs, langMap };
}
