
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { PartialAnnouncement } from '@/interfaces';
import { formatLocalTime } from '@/utils/format-time';
import { getAnnouncement } from '@/services/api';
import { useLang } from './composables';

export default defineComponent({
  setup() {
    const announcementId = useRoute().params.id as string;
    const announcement = ref<PartialAnnouncement>({});
    const { langMap } = useLang();

    const fetchAnnouncements = async() => {
      const { data } = await getAnnouncement({ announcementId });
      announcement.value = data;
    };

    onMounted(() => {
      fetchAnnouncements();
    });

    return {
      announcement,
      formatLocalTime,
      langMap
    };
  }
});
