import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4531af6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "站內通知詳情",
        direction: "vertical",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "類型" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`announcementType.${_ctx.announcement?.type}`)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "發布語系" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.langMap[_ctx.announcement.language] || _ctx.announcement.language), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "標題" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.announcement?.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "通知圖片",
            span: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"300px"},
                src: _ctx.announcement?.notificationPhoto?.path
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "電腦版內容" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.announcement?.content
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "手機版內容" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.announcement?.mobileContent
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "發佈對象" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`announcementTargetUsers.${_ctx.announcement?.targetUsers}`)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "開始時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatLocalTime(_ctx.announcement?.startedAt)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "結束時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.announcement?.endedAt ? _ctx.formatLocalTime(_ctx.announcement?.endedAt) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "開關" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_switch, {
                disabled: "",
                modelValue: _ctx.announcement.isEnabled,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.announcement.isEnabled) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "置頂" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_switch, {
                disabled: "",
                modelValue: _ctx.announcement.isTop,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.announcement.isTop) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}